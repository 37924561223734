import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const ImageBar = () => (
    <StaticQuery
    query={graphql`
        {
            background: imageSharp(original: {src: {regex: "/YUN_0008/"}}) {
                fluid(maxWidth: 1920, maxHeight: 950, cropFocus: CENTER, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    `}
    render={ data => (
        <Img fluid={data.background.fluid} alt="" className="imageBar" />
    )}
    />
)

export default ImageBar